const defaultOptions = [
    {
        label: "15 minutes from now",
        value: "15m",
    },
    {
        label: "30 minutes from now",
        value: "30m",
    },
    {
        label: "1 hour from now",
        value: "1h",
    },
    {
        label: "2 hours from now",
        value: "2h",
    },
    {
        label: "6 hours from now",
        value: "6h",
    },
    { label: "12 hours from now", value: "12h" },
    { label: "24 hours from now", value: "24h" },
];

export default defaultOptions;
