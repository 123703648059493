import React, { useState, useEffect } from "react";
import Select, { Theme } from "react-select";
import styled from "styled-components";
import defaultOptions from "./defaultOptions";
import durationToDate from "./utils";
import type { DurationKey } from "./utils";

type Option = {
    value: string;
    label: string;
};
interface Props {
    options?: Array<Option>;
    value?: string;
    onChange: (value: Date | null) => void;
}

const DeadlineSelector = ({
    options = defaultOptions,
    value,
    onChange,
}: Props) => {
    const defaultValue = value ?? "2h";
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const val = e.target?.value as DurationKey;
        const deadline = durationToDate(val) as Date;
        onChange(deadline);
    };

    return (
        <select defaultValue={defaultValue} onChange={handleChange}>
            {defaultOptions.map(({ label, value }) => (
                <option key={label} value={value}>
                    {label}
                </option>
            ))}
        </select>
    );
};

export default DeadlineSelector;
