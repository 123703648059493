import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import DinnerOptionForm from "../components/DinnerOptionForm";
import {
    DinnerType,
    dinnerOptionsGetAll,
    dinnerOptionsDelete,
} from "../services";

interface DinnerListItemProps extends DinnerType {
    className?: string;
    onSuccess: () => void;
}

const DinnerListItem = ({
    id,
    name,
    description,
    is_restaurant,
    className,
    onSuccess,
}: DinnerListItemProps) => {
    const [isEditMode, setIsEditMode] = useState(false);

    const openEditMode = () => {
        setIsEditMode(true);
    };

    const closeEditMode = () => {
        setIsEditMode(false);
    };

    const handleSuccessAndClose = () => {
        onSuccess();
        closeEditMode();
    };

    const handleDeleteAndClose = async () => {
        const confirm = window.confirm("Are you sure?");
        if (!confirm) {
            return;
        }
        const success = await dinnerOptionsDelete(id);
        if (success) {
            onSuccess();
        }
    };

    const data = { id, name, description, is_restaurant: is_restaurant };
    return isEditMode ? (
        <div className={className}>
            <DinnerOptionForm
                onSuccess={handleSuccessAndClose}
                data={data}
                isEditMode={isEditMode}
            />
            <MenuItem onClick={closeEditMode}>Cancel</MenuItem>
            <MenuItem onClick={handleDeleteAndClose}>Delete</MenuItem>
        </div>
    ) : (
        <div className={className}>
            <Title>{name}</Title>
            <Description>{description}</Description>
            <Menu>
                <MenuItem>Vote</MenuItem>
                <MenuItem onClick={openEditMode}>Edit</MenuItem>
            </Menu>
        </div>
    );
};

const Dinner = () => {
    const [dinners, setDinners] = useState<DinnerType[]>([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);

    const fetchData = async () => {
        const { data, ok } = await dinnerOptionsGetAll();
        if (ok && data) {
            setDinners(data);
        }
    };

    const toggleAddForm = () => {
        setShowAddForm(!showAddForm);
    };

    const closeAddForm = () => {
        setShowAddForm(false);
    };

    const incrementUpdateCount = () => {
        setUpdateCount(updateCount + 1);
    };

    const handleSuccessAndClose = () => {
        incrementUpdateCount();
        closeAddForm();
    };

    useEffect(() => {
        fetchData();
    }, [updateCount]);

    return (
        <Fragment>
            <PageTitle>Dinner Options</PageTitle>
            <DinnerListStyled>
                {dinners
                    ? dinners.map(
                          ({
                              id,
                              name,
                              description,
                              is_restaurant,
                          }: DinnerType) => {
                              return (
                                  <ListItemStyled
                                      key={id}
                                      id={id}
                                      name={name}
                                      description={description}
                                      is_restaurant={!!is_restaurant}
                                      onSuccess={incrementUpdateCount}
                                  ></ListItemStyled>
                              );
                          }
                      )
                    : null}
            </DinnerListStyled>
            {showAddForm ? (
                <DinnerOptionForm onSuccess={handleSuccessAndClose} />
            ) : null}
            <ButtonWrapper>
                <button onClick={toggleAddForm}>
                    {showAddForm ? `Cancel` : "Add Dinner Option"}
                </button>
            </ButtonWrapper>
        </Fragment>
    );
};

export default Dinner;
const DinnerListStyled = styled.ul`
    list-style: none;
    padding: 0;
`;
const ListItemStyled = styled(DinnerListItem)`
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgba(54, 54, 54, 0.3);

    @media (prefers-color-scheme: dark) {
        border: 1px solid rgba(219, 219, 219, 0.3);
    }
`;

const Title = styled.div`
    font-weight: bold;
`;

const Description = styled.div``;

const Menu = styled.div``;
const MenuItem = styled.span`
    display: inline-block;
    margin-right: 5px;
    color: #0070f3;
    &:hover {
        text-decoration: underline;
    }
`;

const PageTitle = styled.h2``;

const ButtonWrapper = styled.div`
    display: flex;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        flex-grow: 2;
    }
`;
