const findDuplicatesInArray = (array: Array<any>) => {
    const ret = [];
    const map = new Map();
    array.forEach((item) => {
        map.set(item.trim(), (map.get(item.trim()) ?? 0) + 1);
    });

    for (let [key, val] of map) {
        if (val > 1) {
            ret.push(key);
        }
    }
    return ret;
};

export default findDuplicatesInArray;
