import React, { useState } from "react";
import styled from "styled-components";
import {
    useForm,
    Controller,
    useFieldArray,
    FieldError,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { findDuplicatesInArray, logger } from "../utils";
import { createQuickVoteService } from "../services";
import { useRunoffContext } from "../context";
import DeadlineSelector from "../components/DeadlineSelector";
import { SubmitButton } from "../components/shared/forms/buttons";
import ConsentCheckbox from "../components/ConsentCheckbox";

export type Voter = {
    name: string;
};

export type Dinner = { name: string };

export type SubmissionErrors = {
    voters: string[];
    dinners: string[];
    deadline: string[];
};

export type FormData = {
    voters: { name: string }[];
    dinners: { name: string }[];
    deadline: Date;
    consent?: boolean;
};

export type FormErrors = {
    voters: { name: FieldError }[];
    dinners: { name: FieldError }[];
    deadline: FieldError;
};

const hourInMilliseconds = 3600000;
const defaultDeadlineBuffer = 2;
interface Props {
    isRunoff?: boolean;
}

const CreateQuickVote = ({ isRunoff }: Props) => {
    const [voterErrors, setVoterErrors] = useState<string[]>([]);
    const [dinnerErrors, setDinnerErrors] = useState<string[]>([]);
    const { runoffData } = useRunoffContext();

    let voters = [{ name: "" }, { name: "" }];
    let dinners = [{ name: "" }, { name: "" }];

    if (isRunoff) {
        voters = runoffData.voters.map((voter) => ({
            name: voter,
        }));
        dinners = runoffData.winningOptions.map((dinner) => ({
            name: dinner,
        }));
    }

    const sanitizeData = (data: FormData) => {
        const { voters, dinners, deadline, consent } = data;
        return {
            deadline,
            voters: voters.map((voter) => ({ name: voter.name.trim() })),
            dinners: dinners.map((dinner) => ({ name: dinner.name.trim() })),
            consent,
        };
    };

    const isEmailEnabled = process.env.REACT_APP_IS_EMAIL_ENABLED === "true";

    const {
        handleSubmit,
        control,
        register,
        watch,
        formState: { errors },
    } = useForm<FormData, FormErrors>({
        mode: "onBlur",
        defaultValues: {
            voters,
            dinners,
            deadline: new Date(
                Date.now() + hourInMilliseconds * defaultDeadlineBuffer
            ),
        },
    });

    const {
        fields: voterFields,
        append: appendVoter,
        remove: removeVoter,
    } = useFieldArray({
        control,
        name: "voters",
    });

    const {
        fields: dinnerFields,
        append: appendDinner,
        remove: removeDinner,
    } = useFieldArray({
        control,
        name: "dinners",
    });

    const validate = (data: any) => {
        const { voters, dinners, deadline } = data;
        const errors: SubmissionErrors = {
            voters: [],
            dinners: [],
            deadline: [],
        };
        let errorCount = 0;

        // min 2 voters
        if (voters.length < 2) {
            errors.voters.push("A minimum of 2 voters are required.");
            errorCount++;
        }
        // min 2 dinners
        if (dinners.length < 2) {
            errors.dinners.push("A minimum of 2 dinners are required.");
            errorCount++;
        }

        // no duplicate voters
        const voterNames = voters.map((voter: Voter) =>
            voter.name.toLowerCase()
        );
        const dupVoters = findDuplicatesInArray(voterNames);

        if (dupVoters.length) {
            errors.voters.push(
                `The following voters have been entered multiple times: ${dupVoters.join(
                    ", "
                )}`
            );
            errorCount++;
        }

        const dinnerNames = dinners.map((dinner: Dinner) =>
            dinner.name.toLowerCase()
        );
        const dupDinners = findDuplicatesInArray(dinnerNames);
        if (dupDinners.length) {
            errors.dinners.push(
                `The following dinners have been entered multiple times: ${dupDinners.join(
                    ", "
                )}`
            );
            errorCount++;
        }

        return { errors, errorCount };
    };

    const navigate = useNavigate();

    const navigateToInfo = (uuid: string) => {
        navigate(`/quickvote/info/${uuid}`);
    };

    const onSubmit = async (data: FormData) => {
        // handle form level errors
        const { errors, errorCount } = validate(data);
        setVoterErrors(errors.voters);
        setDinnerErrors(errors.dinners);
        if (errorCount) {
            return;
        }
        // submit data

        const { status, message, response } = await createQuickVoteService(
            sanitizeData(data)
        );
        if (status === 200) {
            toast.success("Vote successfully created");
            const { uuid } = response;
            navigateToInfo(uuid);
        } else {
            toast.error(message);
        }
    };

    // Watch the 'deadline' field

    const deadline = watch(
        "deadline",
        new Date(Date.now() + hourInMilliseconds * 2) // default to 2 hours from now
    );

    // Format the selected date
    const dateString = deadline.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });
    const footer = `The deadline for voting is ${dateString}`;

    return (
        <Wrapper>
            <h2>Quick Vote</h2>
            <p>
                Use this page to vote for an individual meal. On the next page
                you will receive individual links that you can send to each
                voter.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <legend>Dinner Options</legend>
                    {dinnerFields.map((field, index) => (
                        <div key={field.id}>
                            <label>{`Dinner ${index + 1}`}</label>
                            <InputWrapper>
                                <input
                                    {...register(`dinners.${index}.name`, {
                                        required: "This field is required",
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Must contain at least 2 characters.",
                                        },
                                        maxLength: {
                                            value: 50,
                                            message:
                                                "Must contain fewer than 50 characters",
                                        },
                                    })}
                                    defaultValue={field.name}
                                />
                                <button
                                    type="button"
                                    onClick={() => removeDinner(index)}
                                >
                                    Delete
                                </button>
                            </InputWrapper>
                            {errors.dinners &&
                                errors.dinners[index]?.name?.message && (
                                    <Error>
                                        {errors.dinners[index]?.name?.message ??
                                            ""}
                                    </Error>
                                )}
                        </div>
                    ))}
                    <AddButton
                        type="button"
                        onClick={() => appendDinner({ name: "" })}
                    >
                        Add another dinner
                    </AddButton>
                    {dinnerErrors.map((error, index) => (
                        <Error key={index}>{error}</Error>
                    ))}
                </fieldset>
                <fieldset>
                    <legend>Voting Deadline</legend>
                    <Controller
                        name="deadline"
                        control={control}
                        rules={{
                            validate: (value) =>
                                (value && value > new Date()) ||
                                "Please select a date in the future",
                        }}
                        render={({ field }) => (
                            <DeadlineSelector
                                onChange={(date) => {
                                    logger.info("onChange: ", date);
                                    return field.onChange(date);
                                }}
                            />
                        )}
                    />
                    <Sub>{footer}</Sub>
                    {errors.deadline && (
                        <Error>{errors.deadline.message}</Error>
                    )}
                </fieldset>

                <fieldset>
                    <legend>Voter Information</legend>
                    {voterFields.map((field, index) => (
                        <div key={field.id}>
                            <label>{`Voter ${index + 1}`}</label>
                            <InputWrapper>
                                <input
                                    {...register(`voters.${index}.name`, {
                                        required: "This field is required",
                                        minLength: {
                                            value: 2,
                                            message:
                                                "Must contain at least 2 characters.",
                                        },
                                        maxLength: {
                                            value: 50,
                                            message:
                                                "Must contain fewer than 50 characters",
                                        },
                                    })}
                                    defaultValue={field.name}
                                />
                                <button
                                    type="button"
                                    onClick={() => removeVoter(index)}
                                >
                                    Delete
                                </button>
                            </InputWrapper>

                            {errors.voters &&
                                errors.voters[index]?.name?.message && (
                                    <Error>
                                        {errors.voters[index]?.name?.message ??
                                            ""}
                                    </Error>
                                )}
                        </div>
                    ))}
                    <AddButton
                        type="button"
                        onClick={() => appendVoter({ name: "" })}
                    >
                        Add another voter
                    </AddButton>
                    {voterErrors.map((error, index) => (
                        <Error key={index}>{error}</Error>
                    ))}
                </fieldset>
                {isEmailEnabled && (
                    <Controller
                        name="consent"
                        control={control}
                        rules={{ required: "Consent is required" }}
                        render={({ field }) => (
                            <ConsentCheckbox
                                onChange={(checked: boolean) =>
                                    field.onChange(checked)
                                }
                                error={errors.consent?.message}
                            />
                        )}
                    />
                )}

                <SubmitButton>Start Voting</SubmitButton>
            </form>
        </Wrapper>
    );
};

export default CreateQuickVote;

const Wrapper = styled.div``;

const InputWrapper = styled.div`
    display: flex;
`;

const Sub = styled.sub`
    display: block;
`;

const Error = styled.div`
    color: red;
    font-size: 0.8rem;
    margin-bottom: 5px;
    padding-left: 0px;
    margin-left: 3px;
`;

const AddButton = styled.button`
    margin-top: 10px;
    font-weight: bold;
`;
