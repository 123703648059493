import React from "react";
import styled from "styled-components";

export const SubmitButton = styled.button`
    font-weight: bold;
    background-color: #50c878;
    &:hover {
        background-color: #50c878;
        opacity: 0.8;
    }
    @media (prefers-color-scheme: dark) {
        background-color: teal;
        &:hover {
            background-color: teal;
            opacity: 0.5;
        }
    }
`;
