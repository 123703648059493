import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HeaderLogo = () => {
    return (
        <Link to="/">
            <LogoStyled />
        </Link>
    );
};

const LogoStyled = styled.div`
    display: inline-block;
    width: 200px;
    height: 30px;
    background-image: url("/assets/images/dinner-winner-logo-2-header-logo.png");
    background-size: contain;

    @media (prefers-color-scheme: dark) {
        background-image: url("/assets/images/dinner-winner-header-logo-dark.png");
    }
`;

export default HeaderLogo;
