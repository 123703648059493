import React, { Fragment } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { submitRegistration } from "../services";
import type { RegistrationFormData } from "../services";
import TimezoneSelect from "react-timezone-select";
import { Theme } from "react-select";
import { useMediaQuery } from "react-responsive";

const Registration = () => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegistrationFormData>();

    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate("/login");
    };

    const defaultTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isDarkMode = useMediaQuery({ query: "(prefers-color-scheme: dark)" });

    const test = {
        primary: "green",
        primary75: "green",
        primary50: "green",
        primary25: "green",

        danger: "#DE350B",
        dangerLight: "#FFBDAD",
    };

    const customTheme = (theme: Theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: isDarkMode ? "midnightblue" : "lightgray", // color when hovering over an option
            primary: isDarkMode ? "lightgray" : "darkgray", // background color of the selected option
            neutral0: isDarkMode ? "#161f27" : "white", // background color of the menu
            neutral20: isDarkMode ? "rgba(219, 219, 219, 0.3)" : "lightgray", // border color
            neutral80: isDarkMode ? "white" : "black", // color of the control text
        },
    });

    return (
        <Fragment>
            <PageTitle>Registration</PageTitle>
            <Form
                onSubmit={handleSubmit(async (data: RegistrationFormData) => {
                    const response = await submitRegistration(data);
                    const responseData = await response.json();
                    if (response.ok) {
                        toast.success(responseData.message, {
                            onClose: navigateToLogin,
                        });
                    } else {
                        toast.error(responseData.error);
                    }
                })}
            >
                <fieldset>
                    <legend>Household Information</legend>
                    <Label htmlFor="household_name">Household Name</Label>
                    <Input
                        type="text"
                        id="household_name"
                        placeholder="TheSmithHousehold"
                        {...register("household_name", {
                            required: "Household name is required",
                        })}
                    />
                    {errors.household_name && (
                        <Error>{errors.household_name.message}</Error>
                    )}
                    <FieldDescription>
                        This doesn't need to be your last name, just a unique
                        way to identify your household.
                    </FieldDescription>

                    <Label htmlFor="timezone">Timezone</Label>
                    <Controller
                        name="timezone"
                        control={control}
                        defaultValue={defaultTZ}
                        render={({ field }) => (
                            <TimezoneSelectStyled
                                id="timezone"
                                theme={customTheme}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />

                    <FieldDescription>
                        This is needed for email alerts
                    </FieldDescription>
                </fieldset>
                <fieldset>
                    <legend>Account Information</legend>
                    <Label htmlFor="email">Email Address</Label>
                    <Input
                        type="text"
                        id="email"
                        placeholder="horsedoctor@gmail.com"
                        {...register("email", {
                            required: "Email is required",
                        })}
                    />
                    {errors.email && <Error>{errors.email.message}</Error>}
                    <Label htmlFor="password">Password</Label>
                    <Input
                        type="password"
                        {...register("password", {
                            required: "Password is required",
                        })}
                    />
                    {errors.password && (
                        <Error>{errors.password.message}</Error>
                    )}

                    <Label htmlFor="display_name">Display Name</Label>
                    <Input
                        type="text"
                        placeholder="Beth"
                        {...register("display_name", {
                            required: "Display name is required",
                        })}
                    />
                    {errors.display_name && (
                        <Error>{errors.display_name.message}</Error>
                    )}
                </fieldset>
                <SubmitButton type="submit" value="Get Started!" />
            </Form>
        </Fragment>
    );
};

export default Registration;
const PageTitle = styled.h2``;

const Fieldset = styled.fieldset`
    display: block;
`;
const Form = styled.form``;
const Label = styled.label`
    display: block;
    margin-top: 10px;
    margin-left: 3px; // border-radius / 2
`;
const FieldDescription = styled.small`
    margin-left: 3px;
`;
const Error = styled.small`
    color: red;
    display: block;
    margin-left: 3px;
`;

const Input = styled.input`
    width: 80%;
    box-sizing: border-box;
`;
const SubmitButton = styled.input``;

const TimezoneSelectStyled = styled(TimezoneSelect)`
    width: 80%;
    box-sizing: border-box;
`;
