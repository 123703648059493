const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;

export type DurationKey = "15m" | "30m" | "1h" | "2h" | "6h" | "12h" | "24h";

const durationToDate = (duration: DurationKey) => {
    const strToMsMap = {
        "15m": 15 * MINUTE,
        "30m": 30 * MINUTE,
        "1h": HOUR,
        "2h": 2 * HOUR,
        "6h": 6 * HOUR,
        "12h": 12 * HOUR,
        "24h": 24 * HOUR,
    };

    const durationInMs = strToMsMap.hasOwnProperty(duration)
        ? strToMsMap[duration]
        : 0;

    const deadline = new Date(durationInMs + Date.now());
    return deadline;
};

export default durationToDate;
