import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authenticateCredentials, LoginFormData } from "../services";

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormData>();
    const navigate = useNavigate();

    const navigateToDinners = () => {
        navigate("/dinner");
    };

    const onSubmit = handleSubmit(async (data) => {
        const { status, message } = await authenticateCredentials(data);
        if (status === 200) {
            toast.success("Log In Successful", {
                onClose: navigateToDinners,
            });
        } else {
            toast.error(message || "Log In failed");
        }
    });

    return (
        <WrapperStyled>
            <FormStyled onSubmit={onSubmit}>
                <Title>Log In Required</Title>
                <FieldWrapper>
                    <InputStyled
                        placeholder="Email address"
                        {...register("email", {
                            required: "Email is required",
                        })}
                    />
                    {errors.email && <Error>{errors.email.message}</Error>}
                </FieldWrapper>
                <FieldWrapper>
                    <InputStyled
                        placeholder="Password"
                        type="password"
                        {...register("password", {
                            required: "Password is required",
                        })}
                    />
                    {errors.password && (
                        <Error>{errors.password.message}</Error>
                    )}
                </FieldWrapper>

                <ButtonStyled>Sign In</ButtonStyled>
            </FormStyled>
        </WrapperStyled>
    );
};

export default Login;

const WrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
`;
const FormStyled = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const InputStyled = styled.input`
    margin: 3px;
`;

const ButtonStyled = styled.button`
    margin: 3px 0 0 0;
    width: 197px;
`;

const Error = styled.div`
    color: red;
    font-size: 0.8rem;
    margin-bottom: 5px;
    padding-left: 0px;
    margin-left: 3px;
`;

const SubText = styled.p``;

const Title = styled.h3``;

const FieldWrapper = styled.div`
    display: flex;
    justify-content: left;
    flex-direction: column;
`;
