import fetchWithToken from "../utils/fetchWithToken";

export type DinnerOptionsFormData = {
    id?: number;
    name: string;
    description: string;
    is_restaurant?: boolean;
};

export type DinnerType = {
    id: number;
    name: string;
    description: string;
    is_restaurant: boolean;
};

export interface ServiceResponse<T> {
    data?: T;
    status: number;
    ok: boolean;
    message?: string;
}

export const getAll = async (): Promise<ServiceResponse<DinnerType[]>> => {
    try {
        const response = await fetchWithToken(
            `http://localhost:4000/dinner-options`
        );
        const {ok, status} = response;

        if (ok) {
            const data = await response.json();
            return { data, status, ok };
        } else {
            return {
                status,
                ok,
                message: "Failed to fetch dinner options",
            };
        }
    } catch (error) {
        return {
            status: 500,
            ok: false,
            message: "Failed to fetch dinner options: " + error,
        };
    }
};

export const create = async (data: DinnerOptionsFormData): Promise<ServiceResponse<void>> => {
    const response = await fetchWithToken("http://localhost:4000/dinner-options", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({...data}),
    });
    const {ok, status} = response;
    if (response.ok) {
        return { status, ok };
    } else {
        const errorData = await response.json();
        return { status, message: errorData.message, ok };
    }
};


export const update = async (data: DinnerOptionsFormData) => {
     
    const { id } = data;
    const response = await fetchWithToken(
        `http://localhost:4000/dinner-options/${id}`,
        {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        }
    );

    return response;
};


export const del = async (id: number): Promise<ServiceResponse<void>> => {

    const response = await fetchWithToken(
        `http://localhost:4000/dinner-options/${id}`,
        {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            headers: { "Content-Type": "application/json" },
        }
    );
        const {status, ok} = response;
    return {
        status,
        ok,
        message: ok ? undefined : "Failed to delete dinner option",
    };
};
