import React from "react";
import styled from "styled-components";

interface Props {
    onChange: (checked: boolean) => void;
    error?: string;
}

const ConsentCheckbox = ({ onChange, error }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        console.log(checked);
        onChange(checked);
    };
    return (
        <Wrapper>
            <Container>
                <input
                    type="checkbox"
                    name="consent"
                    id="consent"
                    onChange={handleChange}
                />
                <label htmlFor="consent">
                    I have read the{" "}
                    <a href="/privacy" target="_blank">
                        Privacy Policy
                    </a>
                    . I certify that I have obtained consent to submit the
                    provided emails, confirm that all individuals are 13 years
                    of age or older or have parental/guardian consent, and
                    understand participants can remove their email and stop
                    notifications at any time.
                    {error && <Error key="consent">{error}</Error>}
                </label>
            </Container>
        </Wrapper>
    );
};

export default ConsentCheckbox;

const Container = styled.div`
    display: flex;
`;

const Wrapper = styled.div`
    margin: 15px 0;
`;

const Error = styled.div`
    color: red;
    font-size: 0.8rem;
    margin-bottom: 5px;
    padding-left: 0px;
    margin-left: 3px;
`;
