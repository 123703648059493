// gate and change logging behavior between environments

class Logger {
    env: string;
    isDev: boolean;
    isProd: boolean;

    constructor() {
        this.env = process.env.NODE_ENV;
        this.isDev = this.env === "development";
        this.isProd = this.env === "production";
    }

    info(...args: Array<any>) {
        if (this.isDev) {
            console.log.apply(null, args);
        }
    }

    error(...args: Array<any>) {
        if (this.isDev) {
            console.error.apply(null, args);
        }
    }

    warn(...args: Array<any>) {
        if (this.isDev) {
            console.warn.apply(null, args);
        }
    }
}

const logger = new Logger();

export default logger;
