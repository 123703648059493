import React, { createContext, useContext, useState } from "react";

type RunoffData = {
    voters: string[];
    winningOptions: string[];
};

const initialRunoffData: RunoffData = {
    voters: [],
    winningOptions: [],
};

type RunoffContextType = {
    runoffData: RunoffData;
    setRunoffData: React.Dispatch<React.SetStateAction<RunoffData>>;
};

const RunoffContext = createContext<RunoffContextType | undefined>(undefined);

const useRunoffContext = (): RunoffContextType => {
    const context = useContext(RunoffContext);
    if (!context) {
        throw new Error(
            "useRunoffContext must be used within a RunoffProvider"
        );
    }
    return context;
};

export default RunoffContext;
export { initialRunoffData, useRunoffContext };
export type { RunoffData, RunoffContextType };
