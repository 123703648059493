import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

type MenuItemData = {
    title: string;
    link: string;
    className?: string;
};
interface MenuOptions {
    open: boolean;
    closeMenu: () => void;
    menuItems?: Array<MenuItemData>;
}

const MenuItem = ({ title, link, className }: MenuItemData) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(link);
    };
    return (
        <li className={className} onClick={handleClick}>
            {title}
        </li>
    );
};

const Menu = ({ open, closeMenu, menuItems }: MenuOptions) => {
    return open ? (
        <Shim onClick={closeMenu}>
            <MenuStyled>
                {menuItems &&
                    menuItems.map((item, i) => {
                        const { title, link } = item;
                        return (
                            <MenuItemStyled key={i} title={title} link={link} />
                        );
                    })}
            </MenuStyled>
        </Shim>
    ) : null;
};

export default Menu;

const MenuStyled = styled.ul`
    width: 200px;
    position: fixed;
    top: 50px;
    left: 0;
    margin: 0;
    padding: 0 7px;
    background-color: #fff;
    border: 1px solid rgba(54, 54, 54, 0.3);

    @media (prefers-color-scheme: dark) {
        background-color: #202b38;
        border: 1px solid rgba(219, 219, 219, 0.3);
    }
`;

const MenuItemStyled = styled(MenuItem)`
    list-style: none;
    width: 100%;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    border-bottom: 1px solid rgba(54, 54, 54, 0.3);

    @media (prefers-color-scheme: dark) {
        background-color: #202b38;
        border-bottom: 1px solid rgba(219, 219, 219, 0.3);
    }
    &:last-of-type {
        border: none;
    }
`;
const Shim = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 50px;
    left: 0;
`;
