import React from "react";
import styled from "styled-components";
import IconLight from "./menu-icon-light.png";
import IconDark from "./menu-icon-dark.png";

interface MenuButtonProps {
    toggleMenu: () => void;
}

const MenuButton = ({ toggleMenu }: MenuButtonProps) => {
    return <MenuButtonStyled onClick={toggleMenu} />;
};

export default MenuButton;

const MenuButtonStyled = styled.div`
    width: 34px;
    height: 34px;
    position: absolute;
    top: 7px;
    left: 7px;
    border: 1px solid rgba(54, 54, 54, 1);
    background-image: url(${IconLight});
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;

    @media (prefers-color-scheme: dark) {
        border: 1px solid rgba(219, 219, 219, 1);
        background-image: url(${IconDark});
    }
`;
