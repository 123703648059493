export type RegistrationFormData = {
    household_name: string;
    timezone: string;
    email: string;
    password: string;
    display_name: string;
};

export const submitRegistration = async (data: RegistrationFormData) => {
    const response = await fetch("http://localhost:4000/register", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
    return response;
};