import React from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import { SubmitButton } from "../components/shared/forms/buttons";

function Home() {
    const navigate = useNavigate();

    const handleCTAClick = () => {
        navigate("/quickvote/create");
    };
    return (
        <Wrapper>
            <Logo />
            <SubText>Because "I don't know" isn't a meal</SubText>
            <SubmitButton onClick={handleCTAClick}>
                Vote for tonight's dinner now!
            </SubmitButton>
            <div>No sign up required</div>
        </Wrapper>
    );
}

export default Home;

const SubText = styled.h2`
    margin-top: 0;
    @media preference: (light) {
        color: #333;
    }
`;

const Wrapper = styled.div`
    text-align: center;
`;
