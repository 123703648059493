import fetchWithToken from "../utils/fetchWithToken";
import type { FormData as CreateVoteData } from "../pages/CreateQuickVote";

const SERVER = process.env.REACT_APP_SERVER ?? "http://localhost:4000";

export type NetworkResponse = {
    status: number;
    message: string;
    response: any;
};

export const create = async (data: CreateVoteData) => {
    const response = await fetch(`${SERVER}/api/quickvote/create`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
    const { status, statusText: message } = response;
    const responseJson = await response.json();
    return { status, message, response: responseJson } as NetworkResponse;
};

export const getInfo = async (uuid: string) => {
    const response = await fetch(`${SERVER}/api/quickvote/info/${uuid}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
    });
    const { status, statusText: message } = response;
    const responseJson = await response.json();
    return { status, message, response: responseJson } as NetworkResponse;
};

export const getVotingPageDetails = async (voterUuid: string) => {
    const response = await fetch(`${SERVER}/api/quickvote/vote/${voterUuid}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
    });

    const { status, statusText: message } = response;
    const responseJson = await response.json();
    return { status, message, response: responseJson } as NetworkResponse;
};

export const submitVote = async (
    voterUuid: string,
    rankedIds: Array<string>
) => {
    const response = await fetch(`${SERVER}/api/quickvote/vote/${voterUuid}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            voterUuid,
            rankedIds,
        }),
    });
    const { status, statusText: message } = response;
    const responseJson = await response.json();
    return { status, message, response: responseJson } as NetworkResponse;
};

export const getVotingResults = async (uuid: string, lastUpdated?: number) => {
    const url = lastUpdated
        ? `${SERVER}/api/quickvote/results/${uuid}/${lastUpdated}`
        : `${SERVER}/api/quickvote/results/${uuid}`;
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
    });
    const { status, statusText: message } = response;
    const responseJson = await response.json();
    return { status, message, response: responseJson } as NetworkResponse;
};
