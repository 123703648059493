import React from "react";
import styled from "styled-components";

const Logo = () => {
    return <LogoStyled />;
};

const LogoStyled = styled.div`
    display: inline-block;
    width: 160px;
    height: 120px;
    margin: 50px;
    background-image: url("/assets/images/dinner-winner-plate-logo.png");

    @media (prefers-color-scheme: dark) {
        background-image: url("/assets/images/dinner-winner-plate-logo-dark.png");
    }
`;

export default Logo;
