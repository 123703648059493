import React, { Fragment, useState } from "react";
import styled from "styled-components";
import MenuButton from "./components/MenuButton/MenuButton";
import Menu from "./components/Menu";
import HeaderLogo from "./components/HeaderLogo";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const openMenu = () => {
        setIsMenuOpen(true);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const loggedInMenuItems = [
        { title: "Home", link: "/" },
        { title: "Dinner Options", link: "/dinner" },
        { title: "Voting Results", link: "/results" },
        { title: "Log Out", link: "/logout" },
    ];

    const loggedOutMenuItems = [
        { title: "Home", link: "/" },
        { title: "Quick Vote", link: "/quickvote/create" },
        { title: "FAQs", link: "/faqs" },
        { title: "Privacy Policy", link: "/privacy" },
        // { title: "Sign Up", link: "/register" },
        // { title: "Sign In", link: "/login" },
    ];

    const token = localStorage.getItem("token");

    const menuItems = token ? loggedInMenuItems : loggedOutMenuItems;

    return (
        <Fragment>
            <HeaderStyled>
                <MenuButton toggleMenu={toggleMenu} />
                <HeaderLogo />
            </HeaderStyled>
            <Menu
                open={isMenuOpen}
                closeMenu={closeMenu}
                menuItems={menuItems}
            />
        </Fragment>
    );
};

export default Header;

const HeaderStyled = styled.div`
    width: 100%;
    height: 50px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(54, 54, 54, 0.3);

    @media (prefers-color-scheme: dark) {
        background-color: #202b38;
        border-bottom: 1px solid rgba(219, 219, 219, 0.3);
    }
`;
