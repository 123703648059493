import logOutUser from "./logOutUser";

const fetchWithToken = async (url: string, options: RequestInit = {}) => {
    const token = localStorage.getItem("token");

    if (token) {
        if (options.headers instanceof Headers) {
            options.headers.set("Authorization", token);
        } else {
            const headers = new Headers(options.headers);
            headers.set("Authorization", token);
            options.headers = headers;
        }
    }

    const response = await fetch(url, options);

    // log out user if token has expired
    if (response.status === 401) {
        logOutUser();
    }

    return response;
};

export default fetchWithToken;
