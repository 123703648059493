import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import {
    DinnerOptionsFormData,
    dinnerOptionsCreate,
    dinnerOptionsUpdate,
} from "../../services";

interface Props {
    onSuccess: () => void;
    isEditMode?: boolean;
    data?: DinnerOptionsFormData;
}

const DinnerOptionForm = ({ onSuccess, isEditMode, data }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DinnerOptionsFormData>({ defaultValues: data });
    const recordNewEntry = async (data: DinnerOptionsFormData) => {
        const response = await dinnerOptionsCreate(data);

        return response;
    };

    const updateExistingEntry = async (data: DinnerOptionsFormData) => {
        const response = await dinnerOptionsUpdate(data);

        return response;
    };

    const onSubmit = handleSubmit(async (data: DinnerOptionsFormData) => {
        let response;
        if (isEditMode) {
            response = await updateExistingEntry(data);
        } else {
            response = await recordNewEntry(data);
        }

        if (response.status === 200) {
            onSuccess();
        }
    });

    const submitText = isEditMode ? "Update" : "Submit";

    return (
        <FormStyled onSubmit={onSubmit}>
            <input type="hidden" {...register("id")} value={data?.id} />
            <TitleInput
                type="text"
                placeholder="Name"
                {...register("name", {
                    required: "Name is required",
                })}
            />
            <DescInput
                type="text"
                placeholder="Description"
                {...register("description", {
                    required: "Description is required;",
                })}
            />

            <CheckBoxLabel htmlFor="is_restaurant">
                <input
                    type="checkbox"
                    id="is_restaurant"
                    {...register("is_restaurant")}
                />
                Restaurant
            </CheckBoxLabel>
            <Submit type="submit" value={submitText} />
        </FormStyled>
    );
};

export default DinnerOptionForm;

const FormStyled = styled.form`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: start;
        align-items: stretch;
    }
`;
const TitleInput = styled.input`
    @media (max-width: 600px) {
        margin-right: 0;
    }
`;
const DescInput = styled.input`
    flex-grow: 2;
    @media (max-width: 600px) {
        margin-right: 0;
    }
`;

const CheckBoxLabel = styled.label`
    margin-right: 6px;
`;

const Submit = styled.input`
    margin-right: 0;
`;
