export type LoginFormData = {
    email: string;
    password: string;
};

export const authenticateCredentials = async (data: LoginFormData) => {
    const response = await fetch("http://localhost:4000/login", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
    const {status, statusText: message} = response;
    const responseJson = await response.json();
    const {token} = responseJson;
    
    if (token) {
        localStorage.setItem("token", token);
    }
    
    return { status, message };
};